import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  TitleContent,
  Car,
} from "../components/parts"

const IosApps = () => {
  return (
    <Layout title="iOS apps">
      <Title heading="iOS apps" sub="Utility apps to scratch my itch" />
      <Overview
        project="ios-apps"
        text={[
          "As a means of learning iOS programming, I built and published a few iOS 101 apps using Swift.",
        ]}
      />
      <Toolset project="ios-apps" />
      <TitleContent title="Jar of happiness">
        <div>
          <Row>
            <Col lg={6} md={8} sm={12} xs={12} className="text-center">
              <img
                src="/images/ios-apps/jar-icon.png"
                alt="logo"
                className="ios-icons"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={8} sm={12} xs={12}>
              <Car
                images={[1, 2, 3, 4].map(i => `/images/ios-apps/jar-${i}.png`)}
              />
            </Col>
          </Row>
        </div>
      </TitleContent>
      <TitleContent title="Where was I?">
        <div>
          <Row>
            <Col lg={6} md={8} sm={12} xs={12} className="text-center">
              <img
                src="/images/ios-apps/wherewasi-icon.png"
                alt="logo"
                className="ios-icons"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={8} sm={12} xs={12}>
              <Car
                images={[1, 2, 3].map(
                  i => `/images/ios-apps/wherewasi-${i}.png`
                )}
              />
            </Col>
          </Row>
        </div>
      </TitleContent>
      <TitleContent title="Pomodoro timer">
        <Row>
          <Col lg={6} md={8} sm={12} xs={12} className="text-center">
            <img
              src="/images/ios-apps/pomodoro-icon.png"
              alt="logo"
              className="ios-icons"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={8} sm={12} xs={12}>
            <img src="/images/ios-apps/pomodoro.png" alt="logo" />
          </Col>
        </Row>
      </TitleContent>
    </Layout>
  )
}

export default IosApps
